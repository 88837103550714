export const RouteEnum = {
  HOME: "/",
  LOGIN: "/login",
  OTP: "/verifyotp/:id",
  OTPs: "/verifyotp",
  GENERATEID: "/generate-id",
  SIGNUP: "/signup",
  SELFSERVICEOTP: "/selfservice/verifyotpotp/:id",
  SELFSERVICEOTPs: "/selfservice/verifyotpotp",

  DASHBOARD: "/dashboard",
  DASHBOARDSELFSERVICE: "/selfservice/dashboard",
  ENROLMENT: "/enrolment",
  RINSLIP: "/rin-slip/:id",
  RINSLIPs: "/rin-slip",
  RETRIEVERIN: "retrieve-rin",
  RETRIEVERINs: "retrieve-rin/:id",

  SELFSERVICE: "self-service",

  // PORTFOLIO: "project/portfolio",
  // ADC: "project/adc",
  // VOTENAIJA: "project/vote-naija",
  // UX: "ux",
  // UIDESIGN: "ui-design",

  // CEEVEE: "/CV",
  // ONEONONE: "/one-on-one-session",
  // BOOKACALL: "/cv/book",
  // GRAPHICS: "/graphic",
  // GRAPHICSFORM: "/graphic/forms",
  // DIGITALS: "/digital-customisations",
  // ACCOUNTABILITY: "/accountability-coaching",
};
